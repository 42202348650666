<template>
    <article
        @click="() => openDiscount()"
        ref="$el"
        class="card-main-admin-v2"
        :class="[
            matchId ? 'animated-border' : '',
            isRecommendedFromOthersStores
                ? 'isRecommendedFromOthersStores'
                : isRecommended
                  ? 'isRecommended'
                  : '',
            isMexicoCoupon && canShowCountryStyle ? 'isCountryCouponMx' : '',
            isSpainCoupon && canShowCountryStyle ? 'isCountryCouponEa' : '',
        ]"
    >
        <div
            v-if="isRecommendedFromOthersStores || isRecommended"
            class="w-full rounded-t-xl py-1 text-center text-[13px] font-semibold text-black"
            :class="isRecommendedFromOthersStores ? 'bg-[#EFB4A0]' : isRecommended ? 'bg-[#FAEBD7]' : ''"
        >
            <span class="text-base">⚡</span>
            {{
                isRecommendedFromOthersStores
                    ? 'CUPÓN RECOMENDADO'
                    : isRecommended
                      ? 'CUPÓN MÁS UTILIZADO'
                      : ''
            }}
            <span class="text-base">⚡</span>
        </div>

        <div
            v-if="canShowCountryStyle && (isMexicoCoupon || isSpainCoupon)"
            class="w-full rounded-t-xl py-1 text-center text-[13px] font-semibold"
            :class="[
                isMexicoCoupon ? 'bg-[#016845] text-white' : isSpainCoupon ? 'bg-[#AD1318] text-white' : '',
            ]"
        >
            <span> {{ isMexicoCoupon ? '🇲🇽' : '🇪🇦' }}</span>
            {{ isMexicoCoupon ? 'CUPÓN PARA MÉXICO' : isSpainCoupon ? 'CUPÓN PARA ESPAÑA' : '' }}
            <span>
                {{ isMexicoCoupon ? '🇲🇽' : '🇪🇦' }}
            </span>
        </div>

        <div class="card-main-admin-v2__content">
            <div class="card-main-admin-v2__preview-slot">
                <div class="card-main-admin-v2__left-card" :class="{ 'store-route': storeRoute }">
                    <template v-if="storeRoute && !isRecommendedFromOthersStores">
                        <div v-if="showNewPrice" class="number" v-html="newDiscountPrice"></div>
                        <span
                            class="type"
                            :class="
                                !showNewPrice
                                    ? 'text-lg xs:text-xl sm:text-2xl'
                                    : 'bg-site-primary bg-opacity-10 text-sm'
                            "
                            >{{
                                isOffer
                                    ? $lang.components.cardMainCoupons.offer
                                    : $lang.components.cardMainCoupons.coupon
                            }}</span
                        >
                    </template>
                    <img
                        v-else
                        :src="info.store?.image?.url"
                        :alt="`${info.store?.name} logo`"
                        :onerror="`this.onerror=null;this.src='${defaultImages.store}'`"
                    />
                </div>
            </div>

            <div class="card-main-admin-v2__details-slot">
                <div v-if="storeRoute" class="card-main-admin-v2__store-name">
                    <img
                        :src="info.store?.image?.url || storeInfo?.image?.url"
                        :alt="`${info.store?.name} logo`"
                        loading="lazy"
                        :onerror="`this.onerror=null;this.src='${defaultImages.store}'`"
                    />

                    <p>{{ info.store?.name || storeInfo?.name }}</p>
                </div>
                <h3 class="card-main-admin-v2__title">
                    {{ info.title }}
                </h3>
                <div
                    v-if="
                        (storeRoute || info.is_verified || info.is_exclusive) &&
                        !isRecommendedFromOthersStores
                    "
                >
                    <button
                        v-if="storeRoute"
                        type="button"
                        @click="() => null"
                        class="card-main-admin-v2__see-details"
                    >
                        Ver Detalles
                    </button>
                    <p class="card-main-admin-v2__kind">
                        <span v-if="info.is_verified">
                            ✔ {{ $lang.components.cardMainCoupons.verified }}</span
                        >

                        <span v-if="info.is_exclusive">
                            ✔ {{ $lang.components.cardMainCoupons.exclusive }}</span
                        >
                    </p>
                </div>
                <p
                    v-if="!storeRoute || isRecommendedFromOthersStores"
                    class="card-main-admin-v2__store-link"
                    :class="[
                        isRecommendedFromOthersStores
                            ? 'isRecommendedFromOthersStores'
                            : isRecommended
                              ? 'isRecommended'
                              : '',
                        isMexicoCoupon && canShowCountryStyle ? 'isCountryCouponMx' : '',
                        isSpainCoupon && canShowCountryStyle ? 'isCountryCouponEa' : '',
                    ]"
                >
                    Ver mas de
                    <NuxtLink
                        :to="`/${!$isCupohosting ? $lang.routes.brands + '/' : ''}${info.store?.slug || storeInfo?.slug}`"
                        >{{ info.store?.name || storeInfo?.name }}</NuxtLink
                    >
                </p>
            </div>

            <div class="card-main-admin-v2__button-slot">
                <ButtonAdminCoupon
                    v-if="isCoupon && matchId"
                    class="card-main-admin-v2__button"
                    :discount="info"
                    :store="storeData"
                    :open-coupon="() => null"
                    :btn-disabled="isExpired"
                    :isRecommended="isRecommended"
                    :isRecommendedFromOthersStores="isRecommendedFromOthersStores"
                    :isMexicoButton="canShowCountryStyle && isMexicoCoupon"
                    :isSpainButton="canShowCountryStyle && isSpainCoupon"
                />
                <ButtonAdminDiscount
                    v-else
                    class="card-main-admin-v2__button"
                    :btn-disabled="isExpired"
                    :handler="() => null"
                    :is-offer="isOffer"
                    :isRecommended="isRecommended"
                    :isRecommendedFromOthersStores="isRecommendedFromOthersStores"
                    :isMexicoButton="canShowCountryStyle && isMexicoCoupon"
                    :isSpainButton="canShowCountryStyle && isSpainCoupon"
                    >{{ isCoupon ? 'Ver cupón' : 'Ver Descuento' }}</ButtonAdminDiscount
                >
                <button class="card-main-admin-v2__button-mobile" type="button" @click="() => null">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z" />
                    </svg>
                </button>
            </div>

            <div
                v-if="info.tips"
                class="col-span-12 border-l-2 border-gray-200 px-2 md:col-span-10 md:col-start-3 md:row-start-2"
            >
                <div ref="$tipsSlot" class="card-main-admin-v2__description-slot" :class="{ show: showTips }">
                    <div
                        ref="$tips"
                        v-html="
                            `<p class='font-semibold text-gray-800'>Consejos de ${$lang.brand}:</p>` +
                            info.tips
                        "
                        class="card-main-admin-v2__description-content"
                    ></div>
                    <button
                        v-if="enableToggleTips"
                        @click.stop.capture="showTips = !showTips"
                        type="button"
                        class="card-main-admin-v2__description-button"
                        :class="{ opened: showTips }"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M6.343 7.757L4.93 9.172l7.07 7.07l7.071-7.07l-1.414-1.415L12 13.414z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </article>
</template>

<script lang="ts" setup>
// @ts-ignore
import { isBefore, parseISO } from 'date-fns'
import type { Models } from '~/types/models'
import type { PropType } from 'vue'
import { useRootStore } from '~/store/root'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
    landing: {
        type: String,
    },
    storeInfo: {
        type: Object as PropType<Models.Store>,
        required: false,
    },
    isRecommendedFromOthersStores: {
        type: Boolean,
    },
    storeRoute: {
        type: Boolean,
    },
})

const { defaultImages } = useVariantsDictionary()

const storeData = (props.info.store || props.storeInfo) as Models.Store

const { $openers, $lang } = useNuxtApp()

const showTips = ref(false)

const isOffer = computed(
    () =>
        props.info.type === 'offer' ||
        ((props.info.type === 'coupon' || !props.info.type) && !props.info.code),
)

const isCoupon = computed(() => !isOffer.value)

const isRecommended = ref(props.storeRoute && props.info.is_recommended)

const canShowCountryStyle = ref(
    props.storeRoute && !props.isRecommendedFromOthersStores && !isRecommended.value,
)

const isMexicoCoupon = ref(props.info.featured_for_mx)

const isSpainCoupon = ref(props.info.featured_for_es)

const isExpired = computed(() => {
    return props.info.expired_at ? isBefore(parseISO(props.info.expired_at), new Date()) : false
})

const openDiscount = () =>
    $openers.openDiscount({
        id: props.info.id,
        type: props.info.type,
        slug: props.info.slug,
        landing: props.landing,
        storeSlug: props.info.store?.slug || props.storeInfo?.slug,
    })

const matchId = computed(() => {
    return Route.query.id === String(props.info.id)
})

const RootStore = useRootStore()

const enableToggleTips = ref(false)

const $tips = ref() as Ref<HTMLDivElement>

const $tipsSlot = ref() as Ref<HTMLDivElement>

const maxHeight = 60

onMounted(async () => {
    if (matchId.value) {
        RootStore.setDiscountModal(props.info)
    }

    await nextTick()

    if ($tips.value && $tips.value.clientHeight > maxHeight) {
        enableToggleTips.value = true
        onClickOutside($tipsSlot, () => {
            showTips.value = false
        })
    }
})

const showNewPrice = computed(() => {
    return (
        (isOffer.value && !!props.info.discount_type) ||
        (isCoupon.value &&
            (((props.info.discount_type === 'precio' || props.info.discount_type === 'porcentaje') &&
                props.info.discount_value !== '0') ||
                props.info.discount_type === 'envío gratis'))
    )
})

const newDiscountPrice = computed(() => {
    return props.info.discount_type === 'envío gratis'
        ? `<p>${$lang.components.cardMainGlobal.free_ship}</p>`
        : props.info.discount_type === 'precio'
          ? `<p>${$lang.symbol + props.info.discount_value}</p><p class="term">MENOS</p>`
          : props.info.discount_value === '100'
            ? `<p>${$lang.components.cardMainGlobal.free}</p>`
            : `<p>${props.info.discount_value}%</p><p class="term">MENOS</p>`
})

const Route = useRoute()
</script>

<style lang="postcss">
.card-main-admin-v2 {
    @apply relative cursor-pointer overflow-hidden rounded-xl border border-gray-200 bg-white;
    box-shadow: 0px 0px 4px 3px #0001;
    &.animated-border {
        &::after {
            @apply absolute left-1/2 top-1/2 z-0 h-full w-full -translate-x-1/2 -translate-y-1/2 transform rounded-xl border border-site-primary;
            content: '';
            animation-name: animated-border;
            animation-duration: 1000ms;
            animation-iteration-count: 5;
            animation-fill-mode: forwards;
        }
        &.isRecommendedFromOthersStores {
            &::after {
                @apply border-[#ef764d];
            }
        }
        &.isRecommended {
            &::after {
                @apply border-[#ffc783];
            }
        }
    }
    &.isRecommendedFromOthersStores {
        @apply border-[#ef764d];
    }
    &.isRecommended {
        @apply border-[#fbd19e];
    }
    &.isCountryCouponMx {
        @apply border-[#014D38];
    }
    &.isCountryCouponEa {
        @apply border-[#8B0F14];
    }

    &__content {
        @apply relative z-10 grid grid-cols-12 gap-2 p-2;
    }
    &__preview-slot {
        @apply col-span-3 row-span-2 min-h-[120px] md:col-span-2 md:min-h-[152px];
        @apply self-stretch border-gray-200;
    }
    &__details-slot {
        @apply col-span-8 row-span-2 self-center md:col-span-6 md:col-start-3 md:row-span-1 md:self-end lg:col-span-7;
    }
    &__button-slot {
        @apply col-span-1 row-span-2 w-5 self-center justify-self-end md:col-span-4 md:row-span-1 md:w-[210px] lg:col-span-3;
    }

    --description-leading: 20px;
    --description-closed-max-height: calc(3 * var(--description-leading));
    --description-opened-max-height: 1000px;

    &__description-slot {
        @apply max-h-[--description-closed-max-height] pb-6 transition-all;
        @apply relative overflow-hidden;

        button {
            @apply absolute bottom-0 left-0 right-0 top-0 flex w-full transform items-end justify-center text-center;

            background: linear-gradient(transparent, #fff);

            svg {
                @apply block h-4 w-4 stroke-site-primary object-contain transition-transform;
            }
            &.opened {
                @apply top-auto max-h-max;
                svg {
                    @apply rotate-180 transform;
                }
            }
        }

        &.show {
            @apply max-h-[10000px];
        }
    }

    &__description-content {
        @apply text-sm text-gray-700;
    }

    &__left-card {
        @apply mx-auto flex h-full w-full flex-col items-center justify-center space-y-2 text-center;

        &.store-route {
            @apply rounded border-2 border-dashed p-2;
        }

        .number {
            @apply text-xl font-semibold leading-8 sm:text-2xl sm:leading-9;

            p.term {
                @apply text-base font-normal;
            }
        }

        .type {
            @apply px-1 py-0.5 font-semibold;
        }

        img {
            @apply h-full w-full object-contain;
        }
    }

    &__store-name {
        @apply mb-1 flex items-center space-x-1;
        img {
            @apply block h-6 max-w-14 object-contain;
        }
        p {
            @apply text-xs font-semibold text-gray-800;
        }
    }
    &__store-link {
        @apply text-sm text-gray-800;
        a {
            @apply font-semibold text-site-primary;
        }
        &.isRecommendedFromOthersStores {
            a {
                @apply text-[#ef764d];
            }
        }
        &.isRecommended {
            a {
                @apply text-[#fbd19e];
            }
        }
        &.isCountryCouponMx {
            a {
                @apply text-[#014D38];
            }
        }
        &.isCountryCouponEa {
            a {
                @apply text-[#8B0F14];
            }
        }
    }

    &__title {
        @apply mb-1 line-clamp-2 text-left text-[18px] font-semibold leading-7 text-gray-800 lg:text-[22px] lg:hover:underline;
    }

    &__kind {
        @apply mb-1 inline-block text-sm text-gray-800;
    }

    &__button {
        @apply hidden md:block;
        &-mobile {
            @apply h-5 w-5 md:hidden;
            svg {
                @apply block h-full w-full stroke-site-primary object-contain;
            }
        }
    }

    &__see-details {
        @apply mr-2 inline-block text-xs font-medium text-black lg:hover:underline;
    }
}
@keyframes animated-border {
    from {
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }
    to {
        opacity: 0;
        width: calc(100% + 18px);
        height: calc(100% + 18px);
    }
}
</style>
